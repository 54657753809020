// State
export const state = () => ({
	loading: false,
	inviteAndEarns: {},
	inviteAndEarnPlayers: {},
	inviteAndEarnReports: {},
	playerFinancial: {
		label: [],
		betWinLoss: [],
		depositAmount: [],
		withdrawalAmount: [],
		netCashflow: [],
		turnover: [],
		turnoverDepositRatio: [],
		newDepositAmount: [],
		newWithdrawAmount: [],
		regularDepositAmount: [],
		regularWithdrawAmount: [],
	},
	inviteAndEarnSummary: {
		summary_total_commission: 0,
		summary_total_claim_commission: 0,
		total_friend: 0
	},

	// cancel token
	inviteAndEarnCancelToken: {}
});
// Actions
export const actions = {
	async inviteAndEarns({ commit, state }, data) {
		commit('setInviteAndEarnCancelToken', { inviteAndEarns: this.$axios.CancelToken.source() })

		const params = JSON.parse(JSON.stringify(data))
		params.start_date = data?.start_date?.split(" ")[0] + " 00:00:00"

		// Backend Team, told me to add one second for the end date
		const newEndDate = this.$dayjs(data?.end_date?.split(" ")[0] + " 23:59:59", 'YYYY-MM-DD HH:mm:ss').add(1, 'second')
		params.end_date = newEndDate.format('YYYY-MM-DD HH:mm:ss')
		try {
			commit('loading', true)
			return await this.$axios
				.get(`/players-reward/referral/referrers`, {
					params,
					cancelToken: state.inviteAndEarnCancelToken.inviteAndEarns.token
				})
				.then((response) => {
					commit('inviteAndEarns', response.data)
					commit('loading', false)
				})
		} catch (error) {
			commit('loading', false)
		}
	},
	async inviteAndEarnsSuspend({ commit }, params) {
		try {
			commit('loading', true)
			return await this.$axios
				.put(`players-reward/referral/earning-status/${params.player_id}/${params.status}`)
				.then((response) => {
					commit('loading', false)
					return true;
				})
		} catch (error) {
			commit('loading', false)
			return false;
		}
	},
	async inviteAndEarnPlayers({ commit, state }, data) {
		commit('setInviteAndEarnCancelToken', { inviteAndEarnPlayers: this.$axios.CancelToken.source() })
		try {
			const id = data.id
			delete data.id
			const params = JSON.parse(JSON.stringify(data))
			params.start_date = data?.start_date?.split(" ")[0] + " 00:00:00"
			// Backend Team, told me to add one second for the end date
			const newEndDate = this.$dayjs(data?.end_date?.split(" ")[0] + " 23:59:59", 'YYYY-MM-DD HH:mm:ss').add(1, 'second')
			params.end_date = newEndDate.format('YYYY-MM-DD HH:mm:ss')
			commit('loading', true)
			return await this.$axios
				.get(`/players-reward/referral/refer-friend/${id}`, {
					params,
					cancelToken: state.inviteAndEarnCancelToken.inviteAndEarnPlayers.token
				})
				.then((response) => {
					commit('inviteAndEarnPlayers', response.data)
					commit('loading', false)
				})
		} catch (error) {
			commit('loading', false)
		}
	},
	async inviteAndEarnReports({ commit, state }, data) {
		commit('setInviteAndEarnCancelToken', { inviteAndEarnReports: this.$axios.CancelToken.source() })
		try {
			const id = data.id
			delete data.id
			const params = JSON.parse(JSON.stringify(data))
			params.start_date = data?.start_date?.split(" ")[0] + " 00:00:00"
			// Backend Team, told me to add one second for the end date
			const newEndDate = this.$dayjs(data?.end_date?.split(" ")[0] + " 23:59:59", 'YYYY-MM-DD HH:mm:ss').add(1, 'second')
			params.end_date = newEndDate.format('YYYY-MM-DD HH:mm:ss')
			commit('loading', true)
			return await this.$axios
				.get(`/players-reward/referral/refer-friend-monthly/${id}`, {
					params,
					cancelToken: state.inviteAndEarnCancelToken.inviteAndEarnReports.token
				})
				.then((response) => {
					commit('inviteAndEarnReports', response.data)
					commit('loading', false)
				})
		} catch (error) {
			commit('loading', false)
		}
	},
	async playerFinancialChart({ commit, state }, params) {
		commit('setInviteAndEarnCancelToken', { playerFinancialChart: this.$axios.CancelToken.source() })
		await this.$axios.get(`/statistics/players-reward-stats/referral-finance-chart`, { params, cancelToken: state.inviteAndEarnCancelToken.playerFinancialChart.token })
			.then((response) => {
				commit('setPlayerFinancial', response.data)
			}).catch(() => false)
	},
	async getInviteAndEarnAllSummary({ commit, state }, currency) {
		commit('setInviteAndEarnCancelToken', { getInviteAndEarnAllSummary: this.$axios.CancelToken.source() })
		await this.$axios.get(`/players-reward/referral/referrers/summary/${currency}`, { cancelToken: state.inviteAndEarnCancelToken.getInviteAndEarnAllSummary.token })
			.then((response) => {
				commit('setInviteAndEarnSummary', response.data)
			}).catch(() => false)
	},

}

// Mutations
export const mutations = {
	loading(state, loading) {
		state.loading = loading;
	},
	inviteAndEarns(state, data) {
		state.inviteAndEarns = data;
	},
	inviteAndEarnPlayers(state, data) {
		state.inviteAndEarnPlayers = data;
	},
	inviteAndEarnReports(state, data) {
		state.inviteAndEarnReports = data;
	},
	setPlayerFinancial(state, response) {
		state.playerFinancial = response
	},
	setInviteAndEarnSummary(state, response) {
		state.inviteAndEarnSummary = response
	},
	setInviteAndEarnCancelToken: (state, data) => {
		const oldData = { ...state.inviteAndEarnCancelToken }
		state.inviteAndEarnCancelToken = {
			...oldData, ...data
		}
	}
}